import React, { useState }from 'react';
import {
    Desktop,
    Mobile
} from "./App.js"
import './Navbar.css';

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
        <Mobile>
        <nav className={isOpen ? 'isOpen' : ''}>
            <button className="nav-button" onClick={() => setIsOpen(!isOpen)}>
                {/* Here, you could add an SVG or icon for the hamburger menu */}
                Menu
            </button>
            {isOpen && (
                <>
                <a href="/">Home</a>
                <a href="/about">About</a>
                <a href="/projects">Projects</a>
                <a href="/contact">Contact</a>
                </>
            )}
        </nav>
        </Mobile>
        <Desktop>
        <nav className="navbar">
            <div className="navbar-left">
                <a href="/" className="logo">
                    Home
                </a>
            </div>
            <div className="navbar-center">
                <ul className="nav-links">
                    <li>
                        <a href="/about">About</a>
                    </li>
                    <li>
                        <a href="/projects">Projects</a>
                    </li>
                    <li>
                        <a href="/contact">Contact</a>
                    </li>
                </ul>
            </div>
        </nav>
        </Desktop>
        </>
  );
}

export default Navbar;