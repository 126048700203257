import "./App.css";
import Navbar from './Navbar';
import Foot from './Footer';
import ContactForm from './Contact';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import myPhoto from './myPhoto.jpg';
import { useMediaQuery } from 'react-responsive'
import Tippy from '@tippyjs/react';
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos'; // Import AOS JavaScript
import React, { useEffect } from 'react';

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}

export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}

function Home() {
  return (
    <div>
      <Navbar />
      <Body text={Welcome()}/>
      <Foot />
    </div>
  );
}

function Body(content) {
  return(
    <div id="body">
      {/*<video className="body-bg" autoPlay loop muted playsInline>
        <source src={video1} type='video/mp4' />
      </video>*
       <div className="body-content">
        </div>*/}
      {content.text}
    </div>
  );
}

export function About() {
  return (
    <div>
      <Navbar /> 
      <Body text={Skills()} />
      <Foot />
    </div>
  );
}

export function Projects() {
  return (
    <div>
      <Navbar /> 
      <Body text={Repo()} />
      <Foot />
    </div>
  );
}


export function Contact() {
  return (
    <div>
      <Navbar />
      <Body text={ContactForm()} />
      <Foot />
    </div>
  );
}

const Welcome = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS on page load
  }, []);
  return (
      <section className="welcome">
        <div className="container text-center">
          <h1 data-aos="fade-up">Hi, I am Dhinesh Kumar Manickam... !!!</h1>
          <h2 data-aos="fade-up" data-aos-delay="300">
            I am a software professional with 6+ years of industrial experience. 😊
          </h2>

          <div className="photo-container" data-aos="zoom-in" data-aos-delay="600">
            <img src={myPhoto} alt="Dhinesh Kumar Manickam" className="my-photo img-fluid rounded-circle" />
          </div>

          <div className="links-container" data-aos="fade-up" data-aos-delay="900">
            <a href="https://www.linkedin.com/in/dhinesh-kumar-manickam/" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin fa-3x"></i>
            </a>
            <a href="https://github.com/DhineshKumarmui" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-github fa-3x"></i>
            </a>
          </div>

          <div className="email-container" data-aos="fade-up" data-aos-delay="900" >
          <p>Feel free to contact me at:</p>
          <a href="mailto:dhineshkumar0302@gmail.com" className="email-link">
            dhineshkumar0302@gmail.com
          </a>
          </div>

        </div>
      </section>
  );
};

const Repo = () => {
  return (
    <div>
      <Accordion className="skill-menu">
        <AccordionItem header="Projects" className="skill">
          <div className="spacer" style={{ height: '80px' }}></div>
          <ul className="skill-links">
            <li>
              <Tippy 
                content={<span>A user-friendly React app that provides real-time weather updates and forecasts, sourced from a reliable third-party API. Perfect for planning your day!</span>} 
                placement="top"
                animation="fade"
                arrow={true}
              >
                <a 
                  href="https://dk-weathercheck.netlify.app/" 
                  alt="My Weather website" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  aria-label="Weather Monitor - A React app for checking weather"
                >
                  Weather Monitor
                </a>
              </Tippy>
            </li>
            <li>Updating soon...</li>
          </ul>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

const Skills = () => {
  return (
    <div>
      <Accordion className="skill-menu">
        <AccordionItem header="skills" className="skill">
          <ul className="skill-links">
            <li>Languages -JavaScript, Java, SQL, Python</li>
            <li>Frontend -HTML, CSS, React</li>
            <li>Backend -Node.js, Express, RESTful API Development</li>
            <li>Database -PostgreSQL, MySQL, MariaDB</li>
            <li>Containerization -Docker, Kubernetes</li>
            <li>Frameworks -Selenium, gRPC, Cucumber BDD (REST Assured), Robot Framework, TestNG</li>
            <li>Tools - Git, Maven, Jenkins, Postman, VS Code, Grafana, Prometheus</li>
            <li>Cloud - Azure Devops</li>
          </ul>
        </AccordionItem>
        <AccordionItem header="Experience" className="skill">
          <ul className="skill-links">
            <li>Software Engineer IC3 at Microsoft Corporation(Aug '23 to Sep'24 )</li>
            <li>Software Engineer at Nuance Communications (Jan '21 to July'23)</li>
            <li>Programmer Analyst at Cognizant Technology Solutions(Nov '15 to Jul'18)</li>
          </ul>
        </AccordionItem>
        <AccordionItem header="Education" className="skill">
          <ul className="skill-links">
            <li>Master of Engineering (Quality Systems Engineering)	Concordia University (2018 - 2020 )</li>
            <li>Bachelor of Engineering (Electronics & Communication Engineering)	Anna University (2011 - 2015)</li>
          </ul>
        </AccordionItem>
        <AccordionItem header="Projects" className="skill">
          <ul className="skill-links">
            <li>
              <a href="https://dk-weathercheck.netlify.app/" alt="My_Weather website" target="_blank" rel="noopener noreferrer">Weather Monitor</a>
              </li>
            <li>Updating soon...</li>
          </ul>
        </AccordionItem>
        <AccordionItem header="Certificate" className="skill">
          <ul className="skill-links">
            <li>JavaScript Foundations Professional Certificate by Mozilla</li>
          </ul>
        </AccordionItem>
      </Accordion>
    </div>

  );
};

export function App() {
  return (
  <Home />
  );
};