import React from 'react';
import './Footer.css';

export default function Foot () {
    return(
        <footer >
            <div className='footer'>
                <p>© Dhinesh Kumar Manickam</p>
            </div>
        </footer>
    );
}