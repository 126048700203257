import { useState } from 'react';
import emailjs from '@emailjs/browser';
import "./Contact.css";

const ContactForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);

  const sendEmail = (e) => {
    e.persist();
    e.preventDefault();
    setIsSubmitting(true);

    // Get form values
    const formData = new FormData(e.target);
    const userName = formData.get("user_name").trim();
    const userEmail = formData.get("user_email").trim();
    const message = formData.get("message").trim();

    // Basic validation
    if (!userName || !userEmail || !message) {
      setStateMessage("All fields are required.");
      setIsSubmitting(false);
      return; // Prevent form submission
    }

    emailjs
      .sendForm(
        "service_o2ot6vs",
        "template_j8epv3x",
        e.target,
        "UrOk3ewNnLdDwBJ71"
      )
      .then(
        (result) => {
          setStateMessage("Message sent!");
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // Hide message after 5 seconds
        },
        (error) => {
          setStateMessage("Something went wrong, please try again later");
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // Hide message after 5 seconds
        }
      );

    // Clears the form after sending the email
    e.target.reset();
  };

  return (
    <form onSubmit={sendEmail} id="ContactForm" className="contact">
      <label>Name</label>
      <input type="text" name="user_name" required /><br />
      <label>Email</label>
      <input type="email" name="user_email" required /><br />
      <label>Message</label>
      <textarea name="message" className="message" required /><br />
      <input type="submit" className="submit" value="Send" disabled={isSubmitting} />
      {stateMessage && <p>{stateMessage}</p>}
    </form>
  );
};

  export default ContactForm;
  